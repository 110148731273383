import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['submitButton']

  connect () {
    this.checkSubmitButton()
  }

  checkSubmitButton () {
    this.submitButtonTarget.disabled = !document.querySelector('.CardRadio__Input:checked')
  }
}
