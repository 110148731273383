import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['frameContent', 'loader']

  showLoader () {
    this.frameContentTarget.innerHTML = ''
    this.loaderTargets.forEach(item => item.classList.remove('d-none'))
  }

  hideLoader () {
    this.loaderTargets.forEach(item => item.classList.add('d-none'))
  }
}
